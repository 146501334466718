<template>
  <div class="Home">

    <page-header/>

    <div class="uk-flex uk-flex-center uk-flex-middle uk-background-muted uk-height-viewport">
      <div class="uk-width-large uk-margin uk-section">

        <div v-if="error!==null" class="uk-alert-warning" uk-alert>
          <p v-html="error" class="uk-text-center"></p>
        </div>
        <div v-if="message!==null" class="uk-alert-success" uk-alert>
          <p v-html="message" class="uk-text-center"></p>
        </div>

        <form @submit.prevent="updatePassword" method="post" v-show="currentToken == 'yes'">
          <fieldset class="uk-fieldset">
            <legend class="uk-legend">Password reset</legend>

            <div class="uk-margin">
              <div class="uk-inline uk-width-1-1">
                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
                <input
                  class="uk-input uk-form-large"
                  v-model="password"
                  required
                  placeholder="Password"
                  type="password"
                />
              </div>
            </div>
            <div class="uk-margin">
              <div class="uk-inline uk-width-1-1">
                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
                <input
                  class="uk-input uk-form-large"
                  v-model="confirmPass"
                  required
                  placeholder="comfirm password"
                  type="password"
                />
              </div>
            </div>

            <div class="uk-margin">
              <button
                type="submit"
                class="uk-button uk-button-primary uk-button-primary uk-button-large uk-width-1-1"
              >Reset password
              </button>
            </div>
          </fieldset>

        </form>
      </div>
    </div>

    <page-footer/>

  </div>

</template>

<script>
  import AuthenticationService from '@/services/AuthenticationService'
  import PageHeader from '@/components/Homecmp/Header.vue'
  import PageFooter from '@/components/Homecmp/Footer.vue'

  export default {
    name: 'Home',
    components: {
      PageHeader,
      PageFooter,
    },
    data() {
      return {
        password: '',
        confirmPass: '',
        token: '',
        error: null,
        message: null,
        currentToken: ''
      }
    },
    watch: {},
    created() {
      if (this.$route.query.token) {
        this.token = this.$route.query.token
        this.checkToken(this.$route.query.token)
      } else {
        this.$router.push({name: 'home'})
      }
    },
    methods: {
      async updatePassword() {
        try {
          if (this.password != this.confirmPass || this.password == '') {
            alert("please check your password.");
          } else {
            if (this.password.length > 5){
              const response = await AuthenticationService.updatePassword({
                token: this.token,
                password: this.password
              })
              if (response.data.status == 200) {
                if (response.data.success == true) {

                  alert(response.data.message)
                  this.$router.push({
                    name: 'login'
                  })
                } else {
                  alert(response.data.message)
                }
              } else {
                this.$router.push({
                  name: 'home'
                })
              }
            }
            else
            {
              this.error = "your password should be at least 5 characters"
            }
          }
        } catch (error) {
          this.error = error.response.data.message
        }
      },
      async checkToken(token) {
        try {
          const response = await AuthenticationService.checkToken({
              token: token
          })

          if (response.data.status == 200) {
            if (response.data.success == true) {
                this.currentToken = 'yes'
            } else {
              this.error = response.data.message;
            }
          } else {
            this.error = response.data.message;
          }


          // this.$router.push({
          //   name: 'login'
          // })
        } catch (error) {
          this.error = error.response.data.message
        }
      }
    }
  }
</script>

<style scoped>
</style>
