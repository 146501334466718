import Api from '@/services/Api'

export default {
  register(credentials) {
    return Api().post('auth/signup', credentials)
  },
  checkorgcode(credentials) {
    return Api().post('auth/checkorgcode', credentials)
  },
  login(credentials) {
    return Api().post('auth/signin', credentials)
  },
  idCheck(credentials) {
    return Api().post('auth/idcheck', credentials)
  },
  loginReset(credentials) {
    return Api().post('user/resetpassword', credentials)
  },
  checkToken(params = {}) {
    return Api().get(`user/checktoken`, {
      params: params
    })
  },
  updatePassword(credentials) {
    return Api().post('user/updatepassword', credentials)
  },
  registerExcelUser(credentials){
    return Api().post(`/create/excel/users`, credentials)
  },
  uploadImages(credentials){
    return Api().post(`/upload/zipimages`, credentials)
  }
}
